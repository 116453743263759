<mat-nav-list>
  <!-- Dashboard -->
  <div class="r2s-sidenav-item" mat-list-item>
    <a mat-button type="button" matListItemTitle routerLink="/dashboard" routerLinkActive="r2s-active-link">
      <button type="button" mat-icon-button matListItemIcon class="me-2">
        <mat-icon class="sidenav-icon-display" aria-hidden="true">dashboard</mat-icon>
      </button>Dashboard
    </a>
  </div>

  <!-- Goals -->
  <div class="r2s-sidenav-item" mat-list-item>
    <a mat-button type="button" matListItemTitle routerLink="/goals" routerLinkActive="r2s-active-link">
      <button type="button" mat-icon-button matListItemIcon class="me-2">
        <mat-icon class="sidenav-icon-display" aria-hidden="true">flag</mat-icon>
      </button>Goals
    </a>
  </div>

  <!-- Working Days
  <div class="r2s-sidenav-item" mat-list-item>
    <a mat-button type="button" matListItemTitle routerLink="/work-days" routerLinkActive="r2s-active-link">
      <button type="button" mat-icon-button matListItemIcon class="me-2">
        <mat-icon class="sidenav-icon-display" aria-hidden="true">event</mat-icon>
      </button>Working Days
    </a>
  </div>
-->
  <!-- Desk Log -->
<mat-expansion-panel  mat-list-item>
  <mat-expansion-panel-header class="r2s-report-panel-header">
    <div class="r2s-sidenav-item" mat-list-item>
      <a mat-button type="button" matListItemTitle routerLink="/desk-log" routerLinkActive="r2s-active-link">
        <button type="button" mat-icon-button matListItemIcon class="me-2">
          <mat-icon class="sidenav-icon-display" aria-hidden="true">description</mat-icon>
        </button>Desk Log
      </a>
    </div>
  </mat-expansion-panel-header>

  <!-- Nested menu items under Reports -->
  <mat-nav-list>
    <!-- DLS -->
    <div class="r2s-sidenav-subitem" mat-list-item *ngIf="canAccessForecasting">
      <a mat-button type="button" matListItemTitle routerLink="/desk-log-summary" routerLinkActive="r2s-active-link">
        <button type="button" mat-icon-button matListItemIcon class="me-2">
          <mat-icon class="sidenav-icon-display" aria-hidden="true">summarize</mat-icon>
        </button>DLS
      </a>
    </div>
    <!-- Variable Operations -->
    <div class="r2s-sidenav-subitem" mat-list-item *ngIf="canAccessForecasting">
      <a mat-button type="button" matListItemTitle routerLink="/variable-operations" routerLinkActive="r2s-active-link">
        <button type="button" mat-icon-button matListItemIcon class="me-2">
          <mat-icon class="sidenav-icon-display" aria-hidden="true">summarize</mat-icon>
        </button>Variable Ops
      </a>
    </div>
    <!-- Add more nested menu items as needed -->
  </mat-nav-list>
</mat-expansion-panel>
  <!-- Forecasting -->
  <div class="r2s-sidenav-item" mat-list-item *ngIf="canAccessForecasting">
    <a mat-button type="button" matListItemTitle routerLink="/forecasting" routerLinkActive="r2s-active-link">
      <button type="button" mat-icon-button matListItemIcon class="me-2">
        <mat-icon class="sidenav-icon-display" aria-hidden="true">storm</mat-icon>
      </button>Forecasting
    </a>
  </div>

  <!-- User Data -->
  <div class="r2s-sidenav-item" mat-list-item *ngIf="canAccessForecasting">
    <a mat-button type="button" matListItemTitle routerLink="/goals/user" routerLinkActive="r2s-active-link">
      <button type="button" mat-icon-button matListItemIcon class="me-2">
        <mat-icon class="sidenav-icon-display" aria-hidden="true">account_circle</mat-icon>
      </button>User Data
    </a>
  </div>


  <!-- Tasks Data -->
  <div class="r2s-sidenav-item" mat-list-item *ngIf="canAccessForecasting">
    <a mat-button type="button" matListItemTitle routerLink="/tasks" routerLinkActive="r2s-active-link">
      <button type="button" mat-icon-button matListItemIcon class="me-2">
        <mat-icon class="sidenav-icon-display" aria-hidden="true">task</mat-icon>
      </button>Tasks
    </a>
  </div>

  <!-- Additional menu items if needed -->

</mat-nav-list>
