// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValueFormatterParams,
} from '@ag-grid-community/core';
// rxjs
import {  Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
// Grid Utilities
import {
  ColDef,
  ColGroupDef,
  ISelectCellEditorParams,
} from '@ag-grid-community/core';
// Services
import { HttpHeaderService } from './http-header.service';
// environment
import { environment } from '../../../environments/environment';
import { QuoteDto } from '../interfaces/admin/quote-dto';
import { QuoteCalendarDto } from '../interfaces/admin/quote-calendar-dto';
import { AlertDto } from '../interfaces/admin/alert-dto';
import { UpdateQuoteDto } from '../interfaces/admin/update-quote-dto';
import { UpdateAlertDto } from '../interfaces/admin/update-alert-dto';
import { CreateAlertDto } from '../interfaces/admin/create-alert-dto';
import { CreateQuoteDto } from '../interfaces/admin/create-qoute-dto';
import { DeleteButtonCellRendererComponent } from '../grid-components/delete-button-row-cell-renderer/delete-button-row-cell-renderer.component';
import { QuoteSearchRowCellRendererComponent } from '../grid-components/quote-search-row-cell-renderer/quote-search-row-cell-renderer.component';


@Injectable({
  providedIn: 'root',
})
export class AdminService {
  getDailyQuotesUri = environment.baseUri + '/api/Company/Quotes/all';
  getGlobalAlertsUri = environment.baseUri + '/api/Company/Alerts/all';
  getActiveDailyQuoteUri = environment.baseUri + '/api/Company/Quotes/active';
  getActiveGlobalAlertUri = environment.baseUri + '/api/Company/Alerts/active';
  updateActiveDailyQuoteUri = environment.baseUri + '/api/Company/Quotes/update';
  getScheduledQuotesUri = environment.baseUri + '/api/Company/Quotes/Scheduled';
  updateScheduleQuotesUri = environment.baseUri + "/api/Company/Quotes/";
  updateActiveGlobalAlertUri = environment.baseUri + '/api/Company/Alerts/update';

  searchDailyQuotesUri = environment.baseUri + '/api/DailyQuote/Search/';
  searchGlobalAlertsUri = environment.baseUri + '/api/GlobalAlert/Search/';

  dailyQuoteTestList: QuoteDto[] = [];
  private dailyQuotes: QuoteDto[] | null = null;
  globalAlertTestList: AlertDto[] = [];

  constructor(private http: HttpClient, private httpHeaderService: HttpHeaderService) {
    if (environment.showConsoleLogs) {
      console.log('Admin Service Loaded');
    }
  }

  getAllDailyQuotes(): Observable<QuoteDto[] | null> {
    return this.http
      .get<QuoteDto[]>(this.getDailyQuotesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getAllDailyQuotes - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          const quotes = response.body?? [];
          const filteredQuotes = quotes.filter(quote => quote.quoteText && quote.quoteText !== '');
          this.dailyQuotes = filteredQuotes; // Cache the filtered quotes

          //this.dailyQuotes = quotes; // Cache the quotes
          return filteredQuotes;
        }),
        tap((_) => (this.dailyQuoteTestList = _ || []))
      );
  }

  getQuotes(): Observable<QuoteDto[] | null> {
    return this.dailyQuotes ? of(this.dailyQuotes) : this.getAllDailyQuotes();
  }

  getActiveDailyQuote(): Observable<QuoteDto | null> {
    return this.http
      .get<QuoteDto>(this.getActiveDailyQuoteUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getActiveDailyQuotes - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          return response.body;
        })
      );
  }

  createNewActiveDailyQuote(createQuote: CreateQuoteDto): Observable<CreateQuoteDto | null> {
    return this.http
      .post<CreateQuoteDto>(this.updateActiveDailyQuoteUri, createQuote, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called updateActiveDailyQuote - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          return response.body;
        })
      );
  }

  updateActiveDailyQuote(updateQuote: UpdateQuoteDto): Observable<QuoteDto | null> {
    return this.http
      .post<QuoteDto>(this.updateActiveDailyQuoteUri, updateQuote, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called updateActiveDailyQuote - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          return response.body;
        })
      );
  }

  gridComponents = {
    deleteButtonCellRendererComponent: DeleteButtonCellRendererComponent,
    quoteSearchRowCellRendererComponent: QuoteSearchRowCellRendererComponent,
  };

  getAllGlobalAlerts(): Observable<AlertDto[] | null> {
    return this.http
      .get<AlertDto[]>(this.getGlobalAlertsUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getAllGlobalAlerts - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          return response.body;
        }),
        tap((_) => (this.globalAlertTestList = _ || []))
      );
  }

  getActivelGlobalAlert(): Observable<AlertDto | null> {
    return this.http
      .get<AlertDto>(this.getActiveGlobalAlertUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getActiveGlobalAlert - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          return response.body;
        })
      );
  }

  createNewActiveGlobalAlert(createAlert: CreateAlertDto): Observable<CreateAlertDto | null> {
    return this.http
      .post<CreateAlertDto>(this.updateActiveGlobalAlertUri, createAlert, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called updateActiveGlobalAlert - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          return response.body;
        })
      );
  }

  updateActiveGlobalAlert(updateAlert: UpdateAlertDto): Observable<AlertDto | null> {
    return this.http
      .post<AlertDto>(this.updateActiveGlobalAlertUri, updateAlert, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called updateActiveGlobalAlert - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          return response.body;
        })
      );
  }

  searchDailyQuotes(term: string) {
    if (term === '') {
      return of([]);
    }

    return of(this.dailyQuoteTestList.filter((x) => x.quoteText.includes(term)).map((x) => x.quoteText));
  }

  searchGlobalAlerts(term: string) {
    if (term === '') {
      return of([]);
    }

    return of(this.globalAlertTestList.filter((x) => x.alertText.includes(term)).map((x) => x.alertText));
  }
  getScheduledQuotes(month:number, year:number): Observable<QuoteCalendarDto[] | null> {
    return this.http
      .get<QuoteCalendarDto[]>(this.getScheduledQuotesUri+"/"+year+"/"+month, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getScheduledQuotes - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          const quotes: QuoteCalendarDto[] = response.body||[];
          if (quotes) {
            // Map the date property to a new Date object for each item
            quotes.forEach((quote) => {
              quote.date = new Date(quote.date);
              quote.addedDate = new Date(quote.addedDate);
            });
          }

          return quotes;
        })
      );
  }
  updateScheduledQuote(updateQuote: QuoteCalendarDto): Observable<QuoteCalendarDto | null> {
    const updateQuoteUri = this.updateScheduleQuotesUri +
    updateQuote.alertQuoteId +
    '/QuoteDate/' +
    updateQuote.date.getFullYear() + // Use parentheses to call the method
    '/' +
    (updateQuote.date.getMonth() + 1) + // Month is zero-based, so add 1
    '/' +
    updateQuote.date.getDate();
   return this.http
      .post<QuoteCalendarDto>(updateQuoteUri, updateQuote, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called updateScheduledQuote - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          return response.body;
        })
      );
  }
  deleteScheduledQuote(updateQuote: QuoteCalendarDto): Observable<QuoteCalendarDto | null> {
    const updateQuoteUri = this.updateScheduleQuotesUri +
    updateQuote.alertQuoteId +
    '/QuoteDate/' +
    updateQuote.date.getFullYear() + // Use parentheses to call the method
    '/' +
    (updateQuote.date.getMonth() + 1) + // Month is zero-based, so add 1
    '/' +
    updateQuote.date.getDate();

     return this.http
      .delete<QuoteCalendarDto>(updateQuoteUri,  {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called updateScheduledQuote - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => {
          return response.body;
        })
      );
  }
  scheduledQuotesColumnDefs: (ColDef<QuoteCalendarDto> | ColGroupDef<QuoteCalendarDto>)[] = [

    {
      headerName: 'Action',
      headerClass: 'custom-header-color',
      editable: true,
      resizable: true,
      flex: 1, minWidth: 100,
      cellRenderer: 'deleteButtonCellRendererComponent',
    },
    {
      headerName: 'Date',
      headerClass: 'custom-header-color',
      field: 'date',
      sort: 'desc',
      initialSortIndex: 1,
      cellRenderer: 'datePickerRowCellRendererComponent',
      editable: true,
      resizable: true,
      flex: 1, minWidth: 100
    },{
      headerName: 'Quote',
      headerClass: 'custom-header-color',
      field: 'quoteText',
      editable: true,
      resizable: true,
      cellRenderer: 'quoteSearchRowCellRendererComponent',
      flex: 4, minWidth: 350
    },

    {
      headerName: 'Author',
      headerClass: 'custom-header-color',
      field: 'addedBy',
      editable: true,
      resizable: true,

      flex: 2, minWidth: 200
    },

    {
      headerName: 'Active',
      headerClass: 'custom-header-color',
      field: 'isActiveQuote',
      editable: true,
      resizable: true,
      flex: 1, minWidth: 100
    }

  ];
  quotesColumnDefs: (ColDef<QuoteDto> | ColGroupDef<QuoteDto>)[] = [

    {
      headerName: 'Quote',
      headerClass: 'custom-header-color',
      field: 'quoteText',
      editable: true,
      resizable: true,
      cellRenderer: 'quoteSearchRowCellRendererComponent',
      flex: 3, minWidth: 300,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Active',
      headerClass: 'custom-header-color',
      field: 'isActiveQuote',
      editable: true,
      resizable: true,
      flex: 1,
      minWidth: 100,
      filter: 'agSetColumnFilter',
    filterParams: {
      valueFormatter: (params: ValueFormatterParams) => params.value ? 'Active' : 'Inactive',  // Change filter labels
      values: [true, false],  // Keep the filter logic based on true/false
    }
    }
];

  /** Column Defaults
   */
  defaultColDef: ColDef = {
    flex:1,
    minWidth:100,
    // Don't allow edits inside the grid
    editable: false,
    // Allow filtering
    filter: true,
    // Use floating filter
    floatingFilter: true,
    // Allow resizing of columns horizontally
    resizable: true,
    // Wrap text inside columns:
    wrapText: true,

    // Size height of rows based on row with highest height value
    autoHeight: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true, // Find a way to show the button but have the grid appear correctly...
    },
    filterParams: {
      maxNumConditions: 1,
    },
    // Allow sorting
    sortable: false,
    // Show "unsort" icon
    unSortIcon: false,
    // Prevent user from re-ordering the columns
    suppressMovable: true,
  };

}
